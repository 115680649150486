// JavaScript Document


jQuery( document ).ready(function($) {
    
	$(document).foundation();
	
	
	
	$('.hamburger').on('click', function(){
		
		$('.hamburger').toggleClass('is-active');
		$('.mobile-menu').toggle();
		$('html').toggleClass('locked');

	});
	$(window).scroll(function() {
		 if ($(this).scrollTop() > 250)
		 {
		  $('.sticky-header').addClass("active");
		 }
		 else
		 {
		  $('.sticky-header').removeClass("active");
		 }
	});
	//SLIDER
	var slider = document.getElementById("slider-balk");
		var sliderValue = 0;
		slider.oninput = function() {
		 sliderValue = this.value;
			//SLIDER
			if(sliderValue > 95) {
			$('#contact_form .slidecontainer .slider').removeClass('inactive').addClass('active');
				$('#contact_form .button1').removeAttr("disabled");
			} else {
				$('#contact_form .slidecontainer .slider').removeClass('active').addClass('inactive');
				$('#contact_form .button1').attr("disabled", "true");
			}
		}
	
	//ACCORDION MOBILE MENU
	$('.is-accordion-submenu-parent a').click(function(){
        $(this).unbind('click');
	});
	
//if ($(window).width() > 1023) {
	window.sr = ScrollReveal();

// Customizing a reveal set
	sr.reveal('.rvl', { 
		duration: 500,
		distance:'0px',
		scale:1,
		delay:500
		});
	sr.reveal('.rvl-1', { 
		duration: 1000,
		distance:'0px',
		scale:1,
		delay:1000
		});
	sr.reveal('.chevron-item', { 
		duration: 1000,
		distance:'0px',
		scale:1,
		delay:2200
		});
	sr.reveal('.str', { 
		duration: 500,
		origin: 'bottom',
		scale: 1
	}, 70);
	sr.reveal('.from-left', {
		duration: 500,
		origin: 'left',
		scale: 1,
		distance: '100px',
		delay:200
	});
	
	//CONTACT FORM
	$('#contact_form')
	// to prevent form from submitting upon successful validation
	  .on("submit", function(ev) {
		ev.preventDefault();
		
		console.log("Submit for form id "+ev.target.id+" intercepted");
	 })	
	.on("forminvalid.zf.abide", function(ev,frm) {
		 console.log("Form id "+ev.target.id+" is invalid");
	  })
	.on("formvalid.zf.abide", function(ev,frm) {
//			if (grecaptcha.getResponse() == "") {
//					alert("A.u.b. de reCAPTCHA aanvinken.");
//					return false;
//				} else {
			  	console.log("form is valid");
				$('#contact_form .button2').attr('disabled', 'true');
				$('#contact_form .loading').css('display','inline-block');
				var that = $('#contact_form'),
				url = that.attr("action"),
				method = that.attr("method"),
				data = {};
				that.find("[name]").each( function() {
					var that = $(this),
						name = that.attr("name"),
						value = that.val();

					data[name] = value;
				});
				data['slider'] = sliderValue;
				$.ajax({
					url: url,
					type: method,
					data: data,
					success: function(response) {
						console.log('success');
						$('#contact_form').hide(); 
						$('.form .successMessage').show(); 
						
					},
					error: function(response){
						$('#contact_form .button1').attr('disabled', 'false');
                        $('#contact_form .loading').hide();
						
					}
					
				});
		//	}
	});

	
	
});
